import React, { Component } from "react"
import {
  AboutSection,
  CustomRow,
  AboutImg,
  HeadingLayout,
  SubHeading,
  Heading,
  Role,
  AboutText,
  SliderOuterWrapper,
  AboutGalleryWrapper,
  Button,
  ImageHolder,
  NextPrev,
  PrevImg,
  NextImg,
} from "./aboutus.style"
import { aboutText } from "./about.module.css"
import { Container, Col } from "react-bootstrap"
import "bootstrap/dist/css/bootstrap.min.css"
import { StaticQuery, graphql, Link } from "gatsby"
import GatsImg from "gatsby-image"
import { Popup } from "react-typeform-embed"

import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

class AboutUs extends Component {
  constructor(props) {
    super(props)
    this.next = this.next.bind(this)
    this.previous = this.previous.bind(this)
  }
  openDialogBox(imageArray, idx) {
    this.props.openLightBoxAbout(imageArray, idx)
  }
  next() {
    this.slider.slickNext()
  }

  previous() {
    this.slider.slickPrev()
  }

  render() {
    const settings = {
      arrows: false,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            initialSlide: 1,
          },
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            initialSlide: 1,
          },
        },
        {
          breakpoint: 575,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            initialSlide: 1,
            rows: 1,
          },
        },
      ],
    }

    const aboutPara = (
      <p>
        I hope you enjoy your visit on our website, exploring all that we have
        to offer, from our{" "}
        <Link to="/properties">
          <strong>real estate listings</strong>
        </Link>
        ,{" "}
        <Link to="/#servicesSection">
          <strong>our services</strong>
        </Link>
        , and information on{" "}
        <Link to="/">
          <strong>investing in The Gambia</strong>
        </Link>{" "}
        - Your Gateway to Africa, The New Frontier for Entrepreneurs.
        <br />
        <br />
        If you are searching for{" "}
        <Link to="/">
          <strong>investment opportunities</strong>
        </Link>
        , or just simply for your{" "}
        <Popup id="JQgjddiU" size={80}>
          <buttom
            style={{
              color: "#69b051",
              border: "none",
              outline: "none",
              backgroundColor: "inherit",
              cursor: "pointer",
            }}
          >
            <strong>dream home</strong>
          </buttom>
        </Popup>{" "}
        and thinking about moving to The Gambia for a better quality of life,
        there are many ways we can assist you through our fully integrated{" "}
        <Link to="/concierge">
          <strong>concierge service</strong>
        </Link>
        , helping to make your transition an effortless and seamless one.
      </p>
    )
    return (
      <AboutSection id="aboutSection">
        <Container>
          <CustomRow>
            <Col lg={3}>
              <AboutImg>
                <GatsImg
                  fluid={this.props.AboutData.Img.childImageSharp.fluid}
                  alt=""
                  className="aboutImage"
                />
              </AboutImg>
            </Col>
            <Col lg={9}>
              <HeadingLayout>
                <Heading>{this.props.AboutData.Heading}</Heading>
                <SubHeading>{this.props.AboutData.name}</SubHeading>
                <Role>{this.props.AboutData.role}</Role>
              </HeadingLayout>
              {/* <div
                className={aboutText}
                dangerouslySetInnerHTML={{
                  __html: this.props.html,
                }}
              ></div> */}
              <div className={aboutText}>{aboutPara}</div>

              {/* <SliderOuterWrapper>
                <Slider ref={c => (this.slider = c)} {...settings}>
                  {this.props.AboutData.SliderData.map((imageObj, idx) => {
                    return (
                      <AboutGalleryWrapper>
                        <ImageHolder
                          onClick={this.openDialogBox.bind(
                            this,
                            this.props.AboutData,
                            idx
                          )}
                        >
                          <GatsImg
                            fluid={imageObj.Img.childImageSharp.fluid}
                            alt=""
                            className="aboutUsSliderImg"
                          />
                        </ImageHolder>
                      </AboutGalleryWrapper>
                    )
                  })}
                </Slider>
                <NextPrev>
                  <Button aria-label="Prev Button" onClick={this.previous}>
                    <PrevImg src={this.props.AboutData.PrevImg} alt="" />
                  </Button>
                  <Button aria-label="Next Button" onClick={this.next}>
                    <NextImg src={this.props.AboutData.NextImg} alt="" />
                  </Button>
                </NextPrev>
              </SliderOuterWrapper> */}
            </Col>
          </CustomRow>
        </Container>
      </AboutSection>
    )
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        realestatePage1Json {
          AboutUs {
            Img {
              childImageSharp {
                fluid(quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            Heading
            name
            role
            SliderData {
              Img {
                childImageSharp {
                  fluid(quality: 100) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            PrevImg
            NextImg
          }
        }
        markdownRemark(frontmatter: { title: { eq: "aboutUs" } }) {
          html
        }
      }
    `}
    render={data => (
      <AboutUs
        html={data.markdownRemark.html}
        AboutData={data.realestatePage1Json.AboutUs}
        {...props}
      />
    )}
  />
)
