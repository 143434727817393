import styled from "styled-components"
import {
  SectionSubHeading,
  SectionHeading,
  Commonh6,
} from "../Common/common.style"
import { device } from "../Common/device"

export const AmenitiesSection = styled.section`
  @media ${device.tablet} {
    padding: 0px 10px;
  }
`

export const HeadingLayout = styled.div`
  margin-bottom: 75px;

  @media ${device.tablet} {
    margin-bottom: 60px;
  }
`

export const Heading = styled(SectionHeading)`
  margin-bottom: 0px;

  @media ${device.tablet} {
    margin-bottom: 20px;
  }
`

export const SubHeading = styled(SectionSubHeading)``

export const AmenitiesWrapper = styled.div`
  border-bottom: 1px solid #ebebeb;
  padding: 100px 0px 70px;

  @media ${device.laptop} {
    padding: 80px 0px 50px;
  }
`

export const AmenitiesLayout = styled.div`
  display: flex;
  align-items: center;
  background: #f5f5f5;
  margin-bottom: 30px;
  padding: 20px;
`

export const AmenitiesImage = styled.img`
  width: 40px;
  flex-shrink: 0;
`

export const AmenitiesText = styled(Commonh6)`
  margin-bottom: 0px;
  line-height: 1;
  margin-left: 10px;

  @media ${device.tablet} {
    line-height: 1;
  }

  @media ${device.mobileXL} {
    line-height: 1;
  }
`
