import React, { useState, useEffect } from "react"
import { weatherContainer, weatherCity } from "./weather.module.css"
import axios from "axios"
function Weather() {
  const [data, setData] = useState({})
  const url =
    "https://api.openweathermap.org/data/2.5/weather?lat=13.4457859&lon=-16.579032&appid=2c2dd0fbeed1bb9d8b771f83b68b1d07&units=metric"

  useEffect(() => {
    axios
      .get(url)
      .then(response => {
        setData(response.data)
      })
      .catch(console.error)
  }, [])

  return (
    <div className={weatherContainer}>
      {data.main != undefined && (
        <>
          <h1>
            {data.main?.temp.toFixed()}
            <sup>
              <span>°C</span>
            </sup>
          </h1>
        </>
      )}

      <div className={weatherCity}>
        {data.weather != undefined && (
          <>
            <b>Banjul</b>
            <b>{data.weather[0].description}</b>
          </>
        )}
      </div>
    </div>
  )
}

export default Weather
