import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import "bootstrap/dist/css/bootstrap.min.css"
import {
  AmenitiesSection,
  HeadingLayout,
  SubHeading,
  Heading,
  AmenitiesWrapper,
  AmenitiesLayout,
  AmenitiesImage,
  AmenitiesText,
} from "./amenities.style"

import { useStaticQuery, graphql } from "gatsby"

const Amenities = () => {
  const JSONData = useStaticQuery(graphql`
    query {
      realestatePage1Json {
        Amenities {
          SubHeading
          Heading
          AmenitiesData {
            Img
            AmenitiesText
          }
        }
      }
    }
  `)

  const AmenitiesData = JSONData.realestatePage1Json.Amenities

  return (
    <AmenitiesSection>
      <Container>
        <AmenitiesWrapper>
          <Row>
            <Col md={12}>
              <HeadingLayout>
                <SubHeading>{AmenitiesData.SubHeading}</SubHeading>
                <Heading>{AmenitiesData.Heading}</Heading>
              </HeadingLayout>
            </Col>
          </Row>
          <Row>
            {AmenitiesData.AmenitiesData.map((amnObj, idx) => {
              return (
                <Col lg={3} md={6} sm={6}>
                  <AmenitiesLayout>
                    <AmenitiesImage src={amnObj.Img} alt="" />
                    <AmenitiesText>{amnObj.AmenitiesText}</AmenitiesText>
                  </AmenitiesLayout>
                </Col>
              )
            })}
          </Row>
        </AmenitiesWrapper>
      </Container>
    </AmenitiesSection>
  )
}

export default Amenities
