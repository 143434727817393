import styled from "styled-components"
import { device } from "../Common/device"
import {
  SectionSubHeading,
  Commonpara,
  SectionHeading,
  Commonh5,
} from "../Common/common.style"
import { QuoteLeft } from "@styled-icons/fa-solid/QuoteLeft"
import { QuoteRight } from "@styled-icons/fa-solid/QuoteRight"

export const TestimonialSection = styled.section`
  padding: 100px 0px;
  background: #fafafa;

  @media ${device.tablet} {
    padding: 80px 10px;
  }
`

export const TestimonialWrapper = styled.div``

export const HeadingLayout = styled.div`
  margin-bottom: 35px;

  @media ${device.tablet} {
    margin-bottom: 20px;
  }
`

export const Heading = styled(SectionHeading)`
  margin-bottom: 0px;
`

export const SubHeading = styled(SectionSubHeading)``

export const SliderOuterWrapper = styled.div`
  max-width: 800px;
  margin: 0px auto;
  position: relative;

  .slick-list {
    line-height: 0;
  }

  @media ${device.laptop} {
    margin: 0px -15px; // left/right margin of property layout
  }
`

export const SliderWrapper = styled.div`
  outline: 0;
`

export const TestimonialCard = styled.div`
  display: flex;
  align-items: center;
  background: #fff;
  box-shadow: 0px 0px 15px 1px #ddd;
  margin: 40px;
  padding: 30px;

  @media ${device.laptop} {
    flex-direction: column;
    margin: 15px;
  }
`

export const ImageLayout = styled.div`
  flex-shrink: 0;
  text-align: center;
  width: 28%;

  @media ${device.laptop} {
    width: 100%;
    margin-bottom: 20px;
  }

  .authorImg {
    width: 110px;
    height: 110px;
    object-fit: cover;
    border-radius: 100%;
    margin: 0px auto 20px;
  }
`

export const TextLayout = styled.div``

export const Author = styled(Commonh5)`
  text-align: center;
  margin-bottom: 0px;
  margin-bottom: 5px;
  line-height: 1;

  @media ${device.tablet} {
    padding: 0px;
  }
`

export const Designation = styled(Commonpara)`
  margin-bottom: 0px;
  line-height: 1;
  text-align: center;

  @media ${device.tablet} {
    padding: 0px;
  }
`
export const VerticalDiv = styled.div`
  width: 1px;
  background: #ebebeb;
  height: 150px;
  margin: 0px 30px;
  flex-shrink: 0;

  @media ${device.laptop} {
    display: none;
  }
`
export const StyledIconOpen = styled(QuoteLeft)`
  height: 30px;
  width: 30px;
  color: #69b051;
  opacity: 0.7;
`

export const TestimonialText = styled(Commonpara)`
  margin-bottom: 0px;
  padding: 0px 40px;

  @media ${device.tablet} {
    padding: 20px 0px;
  }
`
export const QuoteHolder = styled.div`
  text-align: right;
`

export const StyleIconClose = styled(QuoteRight)`
  height: 30px;
  width: 30px;
  color: #69b051;
  opacity: 0.7;
`

export const NextPrev = styled.div`
  text-align: center;
`

export const Button = styled.button`
  border: none;
  background: none;
  outline: none;
  line-height: 1;

  :focus {
    outline: 0;
  }
`

export const PrevImg = styled.img`
  width: 40px;
  cursor: pointer;
  transition: all 0.4s;
  :hover {
    transform: scale(1.1);
    transition: all 0.4s;
  }
`

export const NextImg = styled.img`
  width: 40px;
  cursor: pointer;
  transition: all 0.4s;
  :hover {
    transform: scale(1.1);
    transition: all 0.4s;
  }
`
