import React from "react"
import {
  BannerSection,
  BannerContents,
  BannerContentsLeft,
  WeatherContents,
  BannerHeading,
  BannerSubHeading,
  BannerDescription,
  ButtonLayout,
  CallNowBtn,
  WatchVideoAnchor,
  WatchVideoLayout,
  PlayIcon,
  PlayText,
} from "./banner.style"
import { Container } from "react-bootstrap"
import Weather from "../Weather"
import "bootstrap/dist/css/bootstrap.min.css"
import { useStaticQuery, graphql } from "gatsby"

const Banner = () => {
  const JSONData = useStaticQuery(graphql`
    query {
      realestatePage1Json {
        Banner {
          BannerHeading
          BannerSubHeading
          BannerDescription
          WatchVideoAnchor
          BtnText
        }
      }
    }
  `)
  const BannerData = JSONData.realestatePage1Json.Banner

  return (
    <BannerSection id="homeSection">
      <Container>
        <WeatherContents>
          <Weather />
        </WeatherContents>
        <BannerContents>
          <BannerContentsLeft>
            <BannerHeading>{BannerData.BannerHeading}</BannerHeading>
            <BannerSubHeading>{BannerData.BannerSubHeading}</BannerSubHeading>
            <BannerDescription>
              {BannerData.BannerDescription}
            </BannerDescription>
            <ButtonLayout>
              <CallNowBtn href="#">{BannerData.BtnText}</CallNowBtn>
              <WatchVideoAnchor>
                <WatchVideoLayout>
                  <PlayIcon />
                  <PlayText>{BannerData.WatchVideoAnchor}</PlayText>
                </WatchVideoLayout>
              </WatchVideoAnchor>
            </ButtonLayout>
          </BannerContentsLeft>
        </BannerContents>
      </Container>
    </BannerSection>
  )
}

export default Banner
