import styled from 'styled-components';
import {Commonpara,Commonh5} from '../Common/common.style';
import {device} from '../Common/device';

import {Row} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

import BackgroundImg1 from '../../../assets/realestate-1-images/quality-construction-bg.svg';
import BackgroundImg2 from '../../../assets/realestate-1-images/best-amenities-bg.svg';
import BackgroundImg3 from '../../../assets/realestate-1-images/eco-friendly-design-bg.svg';
import BackgroundImg4 from '../../../assets/realestate-1-images/easy-registration-bg.svg';
import BackgroundImg5 from '../../../assets/realestate-1-images/buyers-trust-bg.svg';
import BackgroundImg6 from '../../../assets/realestate-1-images/great-price-bg.svg';

export const FeaturesSection = styled.section`
    padding:100px 0px 70px;
    background:#f9fafc;
    @media ${device.tablet} {
        padding:80px 10px 50px;
    }
`;

export const FeaturesRow = styled(Row)`
    align-items:center;
`;

export const FeaturesLayout = styled.div`
`;

export const FeaturesCard = styled.div`

    ${props => props.position && props.position===1 &&`
        background-image:url(${BackgroundImg1}) !important;
    `}

    ${props => props.position && props.position===2 &&`
        background-image:url(${BackgroundImg2}) !important;
    `}

    ${props => props.position && props.position===3 &&`
        background-image:url(${BackgroundImg3}) !important;
    `}

    ${props => props.position && props.position===4 &&`
        background-image:url(${BackgroundImg4}) !important;
    `}

    ${props => props.position && props.position===5 &&`
        background-image:url(${BackgroundImg5}) !important;
    `}

    ${props => props.position && props.position===6 &&`
        background-image:url(${BackgroundImg6}) !important;
    `}
    background:#fff;
    cursor:pointer;
    padding: 25px;
    min-height: 270px;
    box-shadow: 0px 0px 8px 1px #ddd;
    transition:all .5s;
    margin:0px 0px 30px;
    overflow:hidden;
    background-size: 180px auto;
    background-repeat: no-repeat;
    background-position: right bottom;
 
   

    :hover
    {
        transition:all .5s;
        transform: translateY(-10px);
    }

    @media ${device.tablet} {
    //    margin:10px 0px;
    }
`;

export const FeaturesCardImg = styled.div`
`;

export const FeaturesCardIcon = styled.img`
    height: 60px;
`;

export const FeaturesCardHeading = styled(Commonh5)`
`;

export const FeaturesCardPara = styled(Commonpara)`
`;