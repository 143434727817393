import React from "react"
import {
  FeaturesSection,
  FeaturesLayout,
  FeaturesCard,
  FeaturesCardImg,
  FeaturesCardIcon,
  FeaturesCardHeading,
  FeaturesCardPara,
  FeaturesRow,
} from "./featurecards.style"
import { Container, Row, Col } from "react-bootstrap"
import "bootstrap/dist/css/bootstrap.min.css"

import { useStaticQuery, graphql, Link } from "gatsby"
import Fade from "react-reveal/Fade"

const Features = () => {
  const JSONData = useStaticQuery(graphql`
    query {
      realestatePage1Json {
        Features {
          DelayConstant
          FeaturesLayout {
            FeaturesCardIcon
            FeaturesCardHeading
            FeaturesCardPara
            FeaturesLink
          }
        }
      }
    }
  `)
  const FeaturesData = JSONData.realestatePage1Json.Features
  return (
    <FeaturesSection id="servicesSection">
      <Container>
        <FeaturesRow>
          <Col md="12" lg="12">
            <FeaturesLayout>
              <Row>
                {FeaturesData.FeaturesLayout.map((item, idx) => {
                  return (
                    <Col lg="4" md="6" sm="12">
                      <Fade delay={(idx + 1) * FeaturesData.DelayConstant}>
                        <Link
                          to={item.FeaturesLink}
                          style={{ textDecoration: "none"}}
                        >
                          <FeaturesCard position={idx + 1} >
                            <FeaturesCardHeading>
                              {item.FeaturesCardHeading}
                            </FeaturesCardHeading>
                            <FeaturesCardPara>
                              {item.FeaturesCardPara}
                            </FeaturesCardPara>
                            <FeaturesCardImg>
                              <FeaturesCardIcon
                                src={item.FeaturesCardIcon}
                                alt=""
                              />
                            </FeaturesCardImg>
                          </FeaturesCard>
                        </Link>
                      </Fade>
                    </Col>
                  )
                })}
              </Row>
            </FeaturesLayout>
          </Col>
        </FeaturesRow>
      </Container>
    </FeaturesSection>
  )
}
export default Features
