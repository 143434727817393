import React, { Component } from "react"
import {
  PropertiesSection,
  TopLayout,
  HeadingLayout,
  SubHeading,
  Heading,
  CustomTabList,
  SliderOuterWrapper,
  SliderWrapper,
  PropertyLayout,
  PropertyImgWrapper,
  SpecLayout,
  SizeText,
  SpecItemLayout,
  PriceLayout,
  SpecIcon,
  SpecText,
  Price,
  Contact,
  Button,
  PrevImg,
  NextImg,
  NextPrev,
  CustomLink,
} from "./properties.style"
import { Container, Row, Col } from "react-bootstrap"
import "bootstrap/dist/css/bootstrap.min.css"
import { Tab, Tabs, TabPanel } from "react-tabs"
import "react-tabs/style/react-tabs.css"
import { StaticQuery, graphql, Link } from "gatsby"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import GatsImg from "gatsby-image"

class Properties extends Component {
  constructor(props) {
    super(props)
    this.next = this.next.bind(this)
    this.previous = this.previous.bind(this)
  }

  next() {
    this.slider.slickNext()
  }

  previous() {
    this.slider.slickPrev()
  }

  render() {
    const settings = {
      arrows: false,
      infinite: true,
      speed: 500,
      slidesToShow: 2,
      slidesToScroll: 1,
      rows: this.props.home == true ? 1 : 2,

      responsive: [
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1,
          },
        },
      ],
    }

    return (
      <PropertiesSection id="propertiesSection">
        <Container>
          <Tabs>
            <TopLayout>
              {this.props.home && (
                <HeadingLayout>
                  <SubHeading>
                    {this.props.PropertiesData.SubHeading}
                  </SubHeading>
                  <Heading>{this.props.PropertiesData.Heading}</Heading>
                </HeadingLayout>
              )}

              <CustomTabList>
                {this.props.PropertiesData.TabList.map((item, idx) => {
                  return <Tab>{item.Tab}</Tab>
                })}
              </CustomTabList>
            </TopLayout>
            {this.props.PropertiesData.TabList.map((itemTab, idxTab) => {
              return (
                <TabPanel>
                  <SliderOuterWrapper>
                    <Slider ref={c => (this.slider = c)} {...settings}>
                      {itemTab.TabPanel.map((itemPanel, idxPanel) => {
                        return (
                          <SliderWrapper>
                            <PropertyLayout>
                              <Row>
                                <Col md={6}>
                                  <PropertyImgWrapper>
                                    <GatsImg
                                      fluid={
                                        itemPanel.Img.childImageSharp.fluid
                                      }
                                      alt=""
                                      className="propertyImg"
                                    />
                                  </PropertyImgWrapper>
                                </Col>
                                <Col md={6}>
                                  <SpecLayout>
                                    <SizeText>{itemPanel.SizeText}</SizeText>
                                    <SpecItemLayout>
                                      <SpecIcon
                                        src={
                                          itemPanel.SpecItemLayout[0].SpecIcon
                                        }
                                        alt=""
                                      />
                                      <SpecText>
                                        {itemPanel.SpecItemLayout[0].SpecText}
                                      </SpecText>
                                    </SpecItemLayout>
                                    <SpecItemLayout>
                                      <SpecIcon
                                        src={
                                          itemPanel.SpecItemLayout[1].SpecIcon
                                        }
                                        alt=""
                                      />
                                      <SpecText>
                                        {itemPanel.SpecItemLayout[1].SpecText}
                                      </SpecText>
                                    </SpecItemLayout>
                                    <SpecItemLayout>
                                      <SpecIcon
                                        src={
                                          itemPanel.SpecItemLayout[2].SpecIcon
                                        }
                                        alt=""
                                      />
                                      <SpecText>
                                        {itemPanel.SpecItemLayout[2].SpecText}
                                      </SpecText>
                                    </SpecItemLayout>
                                    <SpecItemLayout>
                                      <SpecIcon
                                        src={
                                          itemPanel.SpecItemLayout[3].SpecIcon
                                        }
                                        alt=""
                                      />
                                      <SpecText>
                                        {itemPanel.SpecItemLayout[3].SpecText}
                                      </SpecText>
                                    </SpecItemLayout>

                                    <PriceLayout>
                                      <Price>{itemPanel.Price}</Price>
                                      <div className="price-converter">
                                        <h5>D8,000,000</h5>
                                        <h5>€142,0000</h5>
                                      </div>
                                    </PriceLayout>
                                    <CustomLink
                                      to={"/properties/" + itemPanel.slug}
                                      key={idxPanel}
                                    >
                                      {itemPanel.Contact}
                                    </CustomLink>
                                  </SpecLayout>
                                </Col>
                              </Row>
                            </PropertyLayout>
                          </SliderWrapper>
                        )
                      })}
                    </Slider>
                    <NextPrev>
                      <Button onClick={this.previous} aria-label="Prev Button">
                        <PrevImg
                          src={this.props.PropertiesData.PrevImg}
                          alt=""
                        />
                      </Button>
                      <Button onClick={this.next} aria-label="Next Button">
                        <NextImg
                          src={this.props.PropertiesData.NextImg}
                          alt=""
                        />
                      </Button>
                    </NextPrev>
                  </SliderOuterWrapper>
                </TabPanel>
              )
            })}
          </Tabs>
        </Container>
      </PropertiesSection>
    )
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        realestatePage1Json {
          Properties {
            SubHeading
            Heading
            TabList {
              Tab
              TabPanel {
                slug
                Img {
                  childImageSharp {
                    fluid(quality: 100) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
                SizeText
                SpecItemLayout {
                  SpecIcon
                  SpecText
                }
                Price
                Contact
              }
            }
            PrevImg
            NextImg
          }
        }
      }
    `}
    render={data => (
      <Properties
        {...props}
        PropertiesData={data.realestatePage1Json.Properties}
      />
    )}
  />
)
